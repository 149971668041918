import React from 'react'
import { navigate } from 'gatsby'
import { isSignedIn } from '../../../lib/auth'

export default class TabsContainer extends React.Component {
  componentDidMount() {
    if (!isSignedIn()) {
      navigate('/marketplace/insights', { replace: true })
    } else {
      navigate('/marketplace/tools/university-explorer', { replace: true })
    }
  }
  render() {
    return <div />
  }
}
